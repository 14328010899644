<script>
    import { onMount, onDestroy } from "svelte";
    import { _ } from "svelte-i18n";
    import * as THREE from "three";

    import { isMobile } from "../../stores/ui";

    import { getOffsetTop, getAspectRatio, getWindowDimensions } from "../../utils/ui";

    import Progress from "../../components/Progress.svelte";
    import Section from "../../components/Section.svelte";

    import Controls from "./components/Controls.svelte";
    import ReplayControls from "./components/ReplayControls.svelte";

    import { modulate, avgArray, maxArray, clamp, distortMesh, scaleMesh } from "../../utils/mesh";

    let _isMobile = false;

    isMobile.subscribe((val) => (_isMobile = val));

    const getCameraZoom = () => {
        if (_isMobile) {
            return 200;
        }
        return 150;
    };

    let _context = null;

    let _src = null;

    const _fftSize = 512;

    let _dataArray = new Uint8Array(_fftSize / 2);

    let _analyser = null;

    let _audio = null;

    const setUpAudioContext = () => {
        if (_context === null) {
            _context = new AudioContext();

            _src = _context.createMediaElementSource(_audio);

            _analyser = _context.createAnalyser();

            _src.connect(_analyser);

            _analyser.connect(_context.destination);

            _analyser.fftSize = _fftSize;
        }
    };

    let isPlaying = false;

    let isFinished = false;

    let container = null;

    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(75, getAspectRatio(), 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });

    const icosahedronGeometry = new THREE.IcosahedronGeometry(10, 10);

    const lambertMaterial = new THREE.MeshNormalMaterial({
        wireframe: true,
    });

    const ball = new THREE.Mesh(icosahedronGeometry, lambertMaterial);

    scene.add(ball);

    ball.position.set(0, 0, 0);

    camera.position.set(0, 0, getCameraZoom());

    let startTime = window.performance.now();
    let lastElapsedTime = window.performance.now();

    let elapsedTime = 0;

    let progress = 0;

    let devMode = __env && __env["APP_ENV"] ? __env["APP_ENV"] === "development" : false;
    let renderSize = 2;

    let FPSLastTimeStamp = window.performance.now();
    let frames = 0;
    let fps = 0;
    let now = window.performance.now();
    let last = now;
    let delta = 0;

    let lowerMaxFr = 0;
    let upperAvgFr = 0;

    let lowerMaxFrBoosted = 0;
    let upperAvgFrBoosted = 0;

    const animate = () => {
        now = window.performance.now();
        delta = (now - last) / 1000;

        renderer.render(scene, camera);

        if (_analyser && _dataArray) {
            // get the average frequency of the sound
            _analyser.getByteFrequencyData(_dataArray);
        }

        const lowerHalfArray = _dataArray.slice(0, _dataArray.length / 2 - 1);
        const upperHalfArray = _dataArray.slice(_dataArray.length / 2 - 1, _dataArray.length - 1);

        const lowerMax = maxArray(lowerHalfArray);
        const upperAvg = avgArray(upperHalfArray);

        lowerMaxFr = lowerMax / lowerHalfArray.length;
        upperAvgFr = upperAvg / upperHalfArray.length;

        lowerMaxFrBoosted = modulate(Math.pow(lowerMaxFr, 0.8), 0, 1, 0, 8) * 2.5;
        upperAvgFrBoosted = modulate(upperAvgFr, 0, 1, 0, 4) * 3;

        distortMesh(ball, lowerMaxFrBoosted, upperAvgFrBoosted);

        ball.rotation.y += 0.1 * delta;
        ball.rotation.x += 0.1 * delta;

        isPlaying = _audio && !_audio.paused;

        // progress
        if (_audio && !_audio.paused) {
            lastElapsedTime = window.performance.now() - startTime;

            const calcElapsedTime = (elapsedTime + lastElapsedTime) / 1000;

            progress = calcElapsedTime / _audio.duration;
        }

        if (devMode) {
            frames++;
            const time = window.performance.now();

            if (time >= FPSLastTimeStamp + 1000) {
                fps = (frames * 1000) / (time - FPSLastTimeStamp);

                FPSLastTimeStamp = time;
                frames = 0;
            }
        }

        last = now;

        requestAnimationFrame(animate);
    };

    animate();

    const onPlay = () => {
        if (_audio === null) {
            return;
        }

        if (!_audio.paused) {
            _audio.pause();

            elapsedTime += lastElapsedTime;
        } else {
            setUpAudioContext();

            _audio.play();

            startTime = window.performance.now();
        }
    };

    const onReplay = () => {
        elapsedTime = 0;

        progress = 0;

        isFinished = false;

        // set timeout so that the animation for the progress
        // bar can finish (it has transion with 0.2s)
        setTimeout(() => {
            if (!_audio) {
                return;
            }

            setUpAudioContext();

            _audio.currentTime = 0;
            _audio.play();

            startTime = window.performance.now();
        }, 200);
    };

    const enterDevMode = (event) => {
        // ctrl + d
        if (event.keyCode === 68 && event.ctrlKey) {
            event.preventDefault();
            devMode = !devMode;
        }
    };

    let hasScrolled = false;

    const onScroll = (_event, init = false) => {
        if (container !== null) {
            const top = getOffsetTop();
            const dimensisons = container.getBoundingClientRect();

            hasScrolled = top !== 0;

            const trigger = dimensisons.height / 3.5;

            if (top >= trigger) {
                container.style.position = `fixed`;
                container.style.top = `-${trigger}px`;
            } else {
                container.style.position = `absolute`;
                container.style.top = `0px`;
            }

            if (top <= trigger || init) {
                const scale = clamp(1 + top * 0.0008, 1, 1.5);

                scaleMesh(ball, scale);

                container.style.opacity = clamp(1 + top * -0.001, 0.3, 1);
            }
        }
    };

    const resize = () => {
        const ratio = getAspectRatio();

        camera.aspect = ratio;
        camera.position.set(0, 0, getCameraZoom());
        camera.updateProjectionMatrix();

        const { width, height, raw } = getWindowDimensions();

        renderer.setSize(width, height);
        renderer.domElement.style.position = "absolute";
        renderer.domElement.style.top = `-${height / 2 - raw.height / 2}px`;
    };

    const onSongUpload = (event) => {
        if (!_audio) {
            return;
        }

        const fileList = event.target.files;

        const song = fileList[0];

        _audio.src = URL.createObjectURL(song);

        _audio.load();

        isFinished = false;

        onPlay();
    };

    let consecutiveTaps = 0;
    let consecutiveTapTimeOut = null;

    const enterDevModeMobile = () => {
        if (consecutiveTapTimeOut) {
            clearTimeout(consecutiveTapTimeOut);
        }

        if (consecutiveTaps >= 10) {
            devMode = true;

            consecutiveTaps = 0;
        }

        if (devMode) {
            return;
        }

        consecutiveTapTimeOut = setTimeout(() => (consecutiveTaps = 0), 200);

        consecutiveTaps++;
    };

    onMount(() => {
        _audio = document.getElementById("audio");

        _audio.addEventListener("ended", () => {
            isFinished = true;
        });

        const ratio = getAspectRatio();

        camera.aspect = ratio;
        camera.position.set(0, 0, getCameraZoom());
        camera.updateProjectionMatrix();

        renderer.setPixelRatio(Math.min(renderSize, window.devicePixelRatio));

        const { width, height, raw } = getWindowDimensions();

        renderer.setSize(width, height);

        container = document.getElementById("3d-container");

        container.appendChild(renderer.domElement);

        renderer.domElement.style.position = "absolute";
        renderer.domElement.style.top = `-${height / 2 - raw.height / 2}px`;

        window.addEventListener("resize", resize, false);

        window.addEventListener("keydown", enterDevMode);

        window.addEventListener("touchstart", enterDevModeMobile);

        window.addEventListener("orientationchange", resize, false);

        onScroll(null, true);

        window.addEventListener("scroll", onScroll, false);
    });

    onDestroy(() => {
        window.removeEventListener("resize", resize);

        window.removeEventListener("orientationchange", resize);

        window.removeEventListener("keydown", enterDevMode);

        window.removeEventListener("scroll", onScroll);

        window.removeEventListener("touchstart", enterDevModeMobile);

        container = null;

        if (_audio && !_audio.paused) {
            _audio.pause();
        }
    });
</script>

<div id="3d-container" class="wrapper" />

<audio id="audio" preload="none" src="./audio/Antonio Vivaldi - Concerto RV.315 op.8-2 L'Estate - 3. Presto (192kbps).mp3" />

<Section
    id="home-section"
    isFullHeight={true}
    skipPaddingTop={true}
    styles={"display: flex; align-items: center; justify-content: center;"}
>
    <Progress width={progress * 100} />
    {#if devMode}
        <div class="debug-container">
            <p>Low Max frequency {lowerMaxFr.toFixed(2)}</p>
            <p>High Avg frequency {upperAvgFr.toFixed(2)}</p>
            <p>Low Max frequency Normalized: {lowerMaxFrBoosted.toFixed(2)}</p>
            <p>High Avg frequency Normalized: {upperAvgFrBoosted.toFixed(2)}</p>
            <p>fps {fps.toFixed(2)}</p>
            <p>delta {delta.toFixed(4)}ms</p>
        </div>
    {/if}

    <div class="floating-card">
        <p>{$_("home.phrase")}</p>
    </div>

    <div class="controls {hasScrolled ? 'hide-controls' : ''}">
        <ReplayControls show={isFinished} replay={onReplay} onfileupload={onSongUpload} />
        <Controls
            show={!isFinished}
            volume={_audio ? _audio.volume : 1}
            changevolume={(vol) => (_audio ? (_audio.volume = vol / 100) : null)}
            {devMode}
            onfileupload={onSongUpload}
            togglePlay={() => onPlay()}
            {isPlaying}
        />
    </div>
</Section>

<style>
    .wrapper {
        height: 200vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .floating-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .floating-card p {
        font-size: 22px;
        text-align: center;
        margin: 0px;
        color: white;
        text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
        outline: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }
    @media screen and (min-width: 1920px) {
        .floating-card p {
            text-shadow: none;
        }
    }

    @media screen and (maxarray-width: 768px) {
        .floating-card p {
            font-size: 18px;
            text-shadow: none;
        }
    }

    .controls {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        z-index: 110;
        opacity: 1;
        transition:
            opacity 0.2s ease-in-out,
            visibility 0.2s ease-in-out;
    }

    .hide-controls {
        opacity: 0;
        visibility: hidden;
    }

    .debug-container {
        position: fixed;
        left: 12px;
        top: 68px;
        padding: 8px;
        margin: 0;
        color: white;
        z-index: 15;
    }

    @media screen and (max-width: 768px) {
        .debug-container {
            top: 48px;
        }
    }

    .debug-container p {
        font-size: 0.5em;
        margin: 0 0 4px 0;
    }

    audio {
        display: none;
    }
</style>
