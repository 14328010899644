<script>
    import { onMount } from "svelte";
    import { fade } from "svelte/transition";
    import { generate } from "shortid";

    export let title = "";
    export let mountCallback = () => {};
    export let top;
    export let left;
    export let width;

    const id = generate();

    onMount(() => {
        mountCallback(document.getElementById(id));
    });
</script>

<div {id} in:fade out:fade style="top: {top}px; left: {left}px; {width ? `width: ${width}px;` : ''}" class="tooltip">{title}</div>

<style>
    .tooltip {
        background: rgb(38, 38, 38);
        border-radius: 4px;
        padding: 4px;
        color: white;
        position: fixed;
        max-width: 200px;
        display: flex;
        flex-wrap: wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        pointer-events: none;
    }
</style>
