<script>
    import { _ } from "svelte-i18n";
    import { generate } from "shortid";

    export let icon = "api";
    export let icon_url = "";

    export let width = "33%";

    export let banner_url = "";

    const id = generate();
</script>

<div class="card" {id} style="--width:{width}">
    <svg class="svg-border" id={`border-${id}`}>
        <defs>
            <linearGradient id={`linear-gradient-${id}`} x1="0%" y1="0%" x2="50%" y2="100%">
                <stop offset="0%" stop-color="#30e098" />
                <stop offset="25%" stop-color="#61c221" />
                <stop offset="50%" stop-color="#6b82e5" />
                <stop offset="75%" stop-color="#a44bd3" />
                <stop offset="100%" stop-color="#c7288a" />
            </linearGradient>
        </defs>
        <rect
            width="calc(100% - 2px)"
            height="100%"
            rx="6"
            ry="6"
            style={`stroke-width: 2px;stroke: url(#linear-gradient-${id});fill: transparent`}
        />
    </svg>
    <div class="card-border-hide" />
    <div class="card-icon">
        {#if icon_url !== ""}
            <img src={icon_url} alt="icon" />
        {:else}
            <span class="material-icons"> {icon} </span>
        {/if}
    </div>
    <div class="content">
        {#if banner_url !== ""}
            <div class="banner" style="background-image: linear-gradient(0deg , rgba(0, 0, 0, 1), transparent), url({banner_url})" alt="icon" />
        {/if}
        <slot name="header">
            <h2>
                {$_("placeholders.header")}
            </h2>
        </slot>
        <div class="text">
            <slot name="content">
                <p>
                    {$_("placeholders.content")}
                </p>
            </slot>
        </div>
    </div>
</div>

<style>
    .card {
        position: relative;
        margin: 10px;
        padding: 16px;
        width: calc(var(--width) - 32px - 2px);
        border-radius: 6px;
    }
    @media screen and (max-width: 1140px) {
        .card {
            margin: 40px 0 20px 0;
            width: calc(100% - 32px);
        }
    }
    .card:first-child {
        margin-left: 0px;
    }
    .card:last-child {
        margin-right: 0px;
    }

    /* content */
    .card .content {
        margin-top: 40px;
    }
    .card .content h2 {
        text-align: center;
    }

    .card .content .banner {
        position: absolute;
        left: 0;
        top: 0;
        height: 240px;
        width: calc(100% - 3px);
        background-size: cover;
        background-position: center;
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        opacity: 0.8;
        z-index: -1;
    }

    .card .content .text {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        padding-right: 6px;
    }
    @media screen and (max-width: 768px) {
        .card .content .text {
            max-height: unset;
        }
    }

    @media screen and (min-width: 769px) {
        .card .content .text::-webkit-scrollbar {
            width: 2px;
        }
        .card .content .text::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 1);
        }
    }

    .card-border-hide {
        position: absolute;
        left: calc(50% - 40px);
        top: -1px;
        height: 1px;
        width: 80px;
        background-color: #000;
        z-index: 2;
    }
    @media screen and (max-width: 768px) {
        .card-border-hide {
            height: 2px;
        }
    }

    .card-icon {
        position: absolute;
        left: calc(50% - 40px);
        top: -40px;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    .card-icon span {
        font-size: 46px;
        color: #fff;
    }

    .card-icon img {
        height: 46px;
        width: 46px;
    }

    .svg-border {
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: -1px;
        left: -1px;
        right: 0px;
        bottom: 0px;
    }
</style>
