<script>
    import { onDestroy, onMount } from "svelte";
    import { fade } from "svelte/transition";
    import { _ } from "svelte-i18n";

    import { height, locations } from "../stores/nav";

    import { getOffsetTop } from "../utils/ui";

    import Language from "./Language.svelte";
    import Tooltip from "./tooltip/Tooltip.svelte";
    import IconButton from "./IconButton.svelte";

    let navHeight = 68;

    height.subscribe((val) => (navHeight = val));

    let location = "home";

    let _locations = null;

    locations.subscribe((val) => (_locations = val));

    const scrollTo = (location) => {
        if (!_locations || !_locations[location]) {
            return;
        }

        window.scroll({
            top: _locations[location].offset,
            left: 0,
            behavior: "smooth",
        });
    };

    let isScrolled = false;

    const scrollTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const onScroll = () => {
        const top = getOffsetTop();

        isScrolled = top !== 0;

        if (!_locations) {
            return;
        }

        const keys = Object.keys(_locations);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            const value = _locations[key];

            if (top >= value.offset && top < value.offset + value.height) {
                location = key;

                break;
            }
        }
    };

    onMount(() => {
        onScroll();

        window.addEventListener("scroll", onScroll, false);
    });

    onDestroy(() => {
        window.removeEventListener("scroll", onScroll);
    });
</script>

<svelte:head>
    <title>{$_("title")}{location !== "home" ? ` | ${$_(`navigation.${location}`)}` : ""}</title>
    <meta name="description" content={$_("description")} />
</svelte:head>

<!-- <nav style="height: {navHeight + 20}px; top:{shouldHideNavBar ? `-${navHeight}px` : '0px'}" on:mouseenter={showNav}> -->
<nav style="height: {navHeight + 20}px;">
    <div class="container" style="height: {navHeight}px; background-color: {isScrolled ? 'black' : 'unset'};">
        <h1 on:click={scrollTo.bind(this, "home")}>{$_("navigation.title")}</h1>

        <div class="util-container">
            <div class="link-container">
                <p class="nav-link {'services' === location ? 'current-link' : ''}" on:click={scrollTo.bind(this, "services")}>
                    {$_("navigation.services")}
                </p>
                <p class="nav-link {'projects' === location ? 'current-link' : ''}" on:click={scrollTo.bind(this, "projects")}>
                    {$_("navigation.projects")}
                </p>
                <p class="nav-link {'about' === location ? 'current-link' : ''}" on:click={scrollTo.bind(this, "about")}>
                    {$_("navigation.about")}
                </p>
                <p class="nav-link {'contact' === location ? 'current-link' : ''}" on:click={scrollTo.bind(this, "contact")}>
                    {$_("navigation.contact")}
                </p>
            </div>
            <Language />
        </div>
    </div>
</nav>

{#if isScrolled}
    <div class="scroll-top-button-contianer" in:fade out:fade>
        <Tooltip position={"left"} title={$_("navigation.controls.scrollTop")}>
            <IconButton onClick={scrollTop} className={"small-button"}>
                <span class="material-icons"> keyboard_arrow_up </span>
            </IconButton>
        </Tooltip>
    </div>
{/if}

<style>
    nav {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        transition: top 0.2s ease-in-out;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        transition: background-color 0.3s ease-in-out;
    }

    @media screen and (max-width: 768px) {
        .container {
            padding: 0px 10px;
        }
    }

    .container h1 {
        font-size: 1.7rem;
        margin: 0;
        cursor: pointer;
    }

    .util-container {
        display: flex;
    }

    .link-container {
        display: flex;
        margin-right: 40px;
    }

    @media screen and (max-width: 768px) {
        .link-container {
            display: none;
        }
    }

    .nav-link {
        color: lightgray;
        text-decoration: none;
        padding: 4px;
        margin: 0;
        margin-right: 40px;
        transition: color 0.2s ease-in-out;
        cursor: pointer;
    }

    .current-link {
        color: white;
    }

    .nav-link:hover {
        color: white;
    }

    .nav-link:last-child {
        margin-right: 4px;
    }

    .scroll-top-button-contianer {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
    @media screen and (max-width: 768px) {
        .scroll-top-button-contianer {
            background-color: #000000;
            border: 1px solid gray;
            right: 14px;
            bottom: 6px;
            border-radius: 50%;
        }
        :global(.small-button) {
            padding: 0px;
        }
    }
    .material-icons {
        color: lightgray;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width: 768px) {
        .material-icons {
            padding: 0;
            height: 32px;
            width: 32px;
        }
    }
</style>
