<script>
    import { _, json } from "svelte-i18n";

    import Section from "../components/Section.svelte";
    import Card from "../components/Card.svelte";
</script>

<Section id={"projects-section"} isFullHeight={true}>
    <h2>{$_("projects.header")}</h2>
    <div class="container">
        <Card width="50%" icon_url={"https://boukken.com/favicon.ico"} banner_url={"https://boukken.com/background.png"}>
            <a href="https://boukken.com" target="_blank" slot="header">
                <h2>
                    {$_("projects.boukken.title")}
                </h2>
            </a>
            <div slot="content">
                {#each Object.keys($json("projects.boukken.description")) as key}
                    <p>{$_(`projects.boukken.description.${key}`)}</p>
                {/each}
            </div>
        </Card>
        <Card width="50%" icon_url={"https://inkyhabit.com/favicon.ico"} banner_url={"https://inkyhabit.com/placeholder.png"}>
            <a href="https://inkyhabit.com" target="_blank" slot="header">
                <h2>
                    {$_("projects.inky.title")}
                </h2>
            </a>
            <div slot="content">
                {#each Object.keys($json("projects.inky.description")) as key}
                    <p>{$_(`projects.inky.description.${key}`)}</p>
                {/each}
            </div>
        </Card>
    </div>
</Section>

<style>
    .container {
        display: flex;
        justify-content: space-evenly;
    }

    .container a {
        color: white;
    }

    @media screen and (max-width: 1140px) {
        .container {
            flex-direction: column;
        }
    }
</style>
