<script>
    import { onMount } from "svelte";
    import { _, json } from "svelte-i18n";
    import { fade } from "svelte/transition";

    import call from "../../utils/call";
    import { init as initRecaptcha, execute } from "../../utils/recaptcha";

    import Section from "../../components/Section.svelte";
    import Input from "../../components/Input.svelte";
    import Textarea from "../../components/Textarea.svelte";
    import Button from "../../components/Button.svelte";
    import Footer from "../../components/Footer.svelte";

    let hasSend = false;

    let loading = false;

    let globalError = null;

    let data = {
        firstName: "",
        lastName: "",
        email: "",
        content: "",
    };

    let errors = {
        email: null,
        content: null,
    };

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validate = () => {
        Object.keys(data).forEach((key) => {
            if (errors[key] !== undefined) {
                errors[key] = data[key] === "" ? "Malformed input value (should not be empty)" : null;

                if (key === "email") {
                    errors[key] = validateEmail(data[key]) === false ? `Malformed email(should have "@" and ".")` : null;
                }
            }
        });

        const hasErrors = errors.email !== null || errors.content !== null;

        return hasErrors;
    };

    const onChange = (event, name) => {
        const value = event.target.value;

        const valueToCheck = event.target.value.replace(/\s+/g, "");

        data[name] = value;

        if (errors[name] !== undefined) {
            errors[name] = valueToCheck === "" ? true : null;

            if (name === "email") {
                errors[name] = validateEmail(value) === false ? true : null;
            }
        }
    };

    const getRepatchaToken = () => {
        return new Promise((resolve, reject) => {
            try {
                execute(resolve);
            } catch (error) {
                reject(error);
            }
        });
    };

    const onSubmit = async (_token) => {
        try {
            const hasErrors = validate();

            if (hasErrors) {
                return;
            }

            loading = true;

            const token = await getRepatchaToken();

            const response = await call("/api/send-contact-form", "post", { ...data, token: token })
                .then((res) => res)
                .catch((res) => res);

            globalError = response.errors !== null ? response.errors.code : null;

            loading = false;

            hasSend = response.errors === null && response.data !== null && response.data.success;
        } catch (error) {
            console.log(error);
        }
    };

    onMount(() => {
        initRecaptcha();
    });
</script>

<Section
    id={"contact-section"}
    isFullHeight={true}
    isInnerFullHeight={true}
    innerStyles="display: flex; flex-direction: column; justify-content: space-between;"
>
    <div class="wrapper">
        <h2>{$_("contact.header")}</h2>
        <div class="container">
            <div class="content">
                <!-- <h3>{$_("contact.subheader")}</h3> -->
                {#each Object.keys($json("contact.content")) as key, index}
                    <p>{$_(`contact.content.${key}`)}</p>
                {/each}
            </div>
            {#if hasSend}
                <div class="thank-you-container" in:fade>
                    <span class="material-icons"> task_alt </span>
                    <p>{$_("contact.form.thankYou")}</p>
                </div>
            {:else}
                <div class="form">
                    <div class="input-row">
                        <Input onchange={onChange} label={$_("contact.form.firstName")} value={data["firstName"]} name="firstName" />
                        <Input onchange={onChange} label={$_("contact.form.lastName")} value={data["lastName"]} name="lastName" />
                    </div>
                    <Input
                        onchange={onChange}
                        label={$_("contact.form.email")}
                        error={errors["email"] ? $_("contact.errors.emailError") : null}
                        value={data["email"]}
                        name="email"
                        style="margin-top: 12px;"
                    />
                    <Textarea
                        onchange={onChange}
                        label={$_("contact.form.message")}
                        error={errors["content"] ? $_("contact.errors.inputError") : null}
                        value={data["content"]}
                        name="content"
                        style="margin-top: 12px;"
                    />
                    <Button {loading} onclick={onSubmit} label={$_("contact.form.submit")} style="margin-top: 12px;" />
                    {#if globalError !== null}
                        <p in:fade class="error">{$_(`contact.serverErrors.${globalError}`)}</p>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
    <Footer />
</Section>

<style>
    .wrapper {
        display: flex;
        flex-direction: column;
    }
    .container {
        display: flex;
        justify-content: space-between;
    }
    .form {
        width: calc(50% - 20px);
    }
    .content {
        width: calc(50% - 20px);
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        width: 36px;
        height: 36px;
    }
    @media screen and (max-width: 1250px) {
        .container {
            flex-direction: column;
        }
        .content {
            width: 100%;
        }
        .form {
            margin-top: 20px;
            width: 100%;
        }
    }
    .thank-you-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(50% - 20px);
    }
    .thank-you-container span {
        font-size: 15vw;
        height: 15vw;
        width: 15vw;
    }
    .thank-you-container p {
        margin-top: 10px;
    }
    @media screen and (max-width: 1250px) {
        .thank-you-container {
            margin-top: 40px;
            width: 100%;
        }
        .thank-you-container span {
            font-size: 30vw;
            height: 30vw;
            width: 30vw;
        }
    }
    @media screen and (max-width: 768px) {
        .thank-you-container span {
            font-size: 50vw;
            height: 50vw;
            width: 50vw;
        }
    }
    .input-row {
        display: flex;
        width: 100%;
    }
    :global(.input-row > div) {
        width: calc(50% - 5px) !important;
    }
    :global(.input-row > div:first-child) {
        padding-right: 5px;
    }

    :global(.input-row > div:nth-child(2)) {
        padding-left: 5px;
    }
    @media screen and (max-width: 768px) {
        .input-row {
            display: flex;
            flex-direction: column;
        }
        :global(.input-row > div) {
            width: 100% !important;
        }
        :global(.input-row > div:first-child) {
            padding-right: 0;
        }

        :global(.input-row > div:nth-child(2)) {
            padding-left: 0;
            margin-top: 12px;
        }
    }
    .error {
        color: red;
        margin: 0;
    }
</style>
