<script>
    import { onDestroy, onMount, afterUpdate } from "svelte";

    import { isMobile } from "../../stores/ui";

    import TooltipRoot from "./TooltipRoot.svelte";

    export let title = "";

    export let position = "top";

    let prevTitle = title;
    let caller = null;
    let tooltip = null;
    let isOpen = false;
    let top;
    let left;
    let width = null;
    let _isMobile = false;

    isMobile.subscribe((val) => (_isMobile = val));

    const open = async (event) => {
        if (!isOpen) {
            isOpen = true;
            caller = event.target;

            // close the tooltip automaticly on mobile as there is not maouse levase event
            if (_isMobile) {
                setTimeout(() => {
                    isOpen = false;
                }, 1000);
            }
        }
    };

    const close = () => {
        if (isOpen) {
            isOpen = false;
            caller = null;
        }
    };

    const onElementMount = (tooltipEl) => {
        tooltip = tooltipEl;

        calculatePosition();
    };

    const calculatePosition = async () => {
        if (!tooltip || !caller || !isOpen) {
            close();

            return;
        }

        // wait 50ms so that the component gets rendered and we can correct dimensions
        await setTimeout(() => {}, 50);

        const tooltipDimensions = tooltip.getBoundingClientRect();

        const callerDimesions = caller.getBoundingClientRect();

        const windowDimensions = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        if (position === "left") {
            top = callerDimesions.top + callerDimesions.height / 2 - tooltipDimensions.height / 2;

            left = callerDimesions.left - tooltipDimensions.width + 10;

            return;
        }

        top = callerDimesions.top - tooltipDimensions.height;

        left = callerDimesions.left - tooltipDimensions.width / 2 + callerDimesions.width / 2;

        if (tooltipDimensions.width > windowDimensions.width) {
            left = 0;
            width = windowDimensions.width;

            return;
        }

        if (left < 0) {
            left = 5;
            width = null;

            return;
        }

        if (left + tooltipDimensions.width > windowDimensions.width) {
            const difference = left + tooltipDimensions.width - windowDimensions.width;

            left = left - difference - 5;
            width = null;

            return;
        }
    };

    onMount(() => {
        window.addEventListener("scroll", close);

        window.addEventListener("resize", calculatePosition);
    });

    afterUpdate(() => {
        if (prevTitle !== title) {
            calculatePosition();
            prevTitle = title;
        }
    });

    onDestroy(() => {
        window.removeEventListener("scroll", close);

        window.removeEventListener("resize", calculatePosition);
    });
</script>

<div on:focus={open} on:mouseover={open} on:blur={close} on:mouseleave={close}>
    <slot />
</div>

{#if isOpen}
    <TooltipRoot {top} {width} {left} {title} mountCallback={onElementMount} />
{/if}
