<script>
    import SocialLink from "./SocialLink.svelte";

    const currentYear = new Date().getFullYear();
</script>

<div class="footer">
    <div class="social">
        <SocialLink href={"https://www.instagram.com/quantizeltd/"}>
            <span class="icon-instagram" />
        </SocialLink>
        <SocialLink href={"mailto:support@quantizebg.com"}>
            <span class="icon-google-plus" />
        </SocialLink>
        <SocialLink href={"https://twitter.com/QuantizeLtd"}>
            <span class="icon-twitter" />
        </SocialLink>
    </div>
    <p>© 2019 - {currentYear} Quantize. All rights reserved.</p>
</div>

<style>
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
    }

    .footer p {
        margin: 20px 0;
    }

    @media screen and (max-width: 768px) {
        .footer p {
            font-size: 14px;
        }
        .footer {
            margin-bottom: 70px;
        }
    }

    .footer .social {
        display: flex;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        width: 36px;
        height: 36px;
    }
</style>
